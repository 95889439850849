import classNames from 'classnames';
import { CSSProperties, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { capitalize } from 'lib/humanize';

const StyledDiv = styled.div<{
  top: number;
  left: number;
  right: number;
  bottom: number;
  componentWidth: number;
  componentHeight: number;
}>`
  position: absolute;
  text-align: center;
  backdrop-filter: blur(64px);
  padding: var(--double-unit);
  border-radius: var(--double-unit);
  background: rgba(var(--c-rgb-neutral-1000), 0.07);

  &.fullWidth {
    left: var(--unit);
    right: var(--unit);
  }

  &.anchorTop.alignStart:not(.fullWidth),
  &.anchorBottom.alignStart:not(.fullWidth) {
    left: ${({ left }) => left}px;
  }

  &.alignCenter {
    left: ${({ componentWidth, left, right }) =>
      left + (right - left - componentWidth) / 2}px;
  }

  &.anchorTop {
    top: calc(
      ${({ top, componentHeight }) => top - componentHeight}px - var(
          --double-unit
        )
    );
  }

  &.anchorBottom {
    top: calc(${({ bottom }) => bottom}px + var(--double-unit));
  }
`;

type Props = {
  children: ReactNode;
  positionProps?: Partial<{
    top: number;
    left: number;
    right: number;
    bottom: number;
    ready: boolean;
  }>;
  className?: string;
  fullWidth?: boolean;
  style?: CSSProperties;
  anchor?: 'top' | 'bottom';
  align?: 'start' | 'center' | 'end';
};

export const ExtraMessageWithBlurredBg = ({
  style,
  anchor,
  align,
  fullWidth,
  children,
  className,
  positionProps = {},
}: Props) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const {
    top = 0,
    left = 0,
    right = 0,
    bottom = 0,
    ready = true,
  } = positionProps;

  if (!ready) {
    return null;
  }

  return (
    <StyledDiv
      ref={setRef}
      style={style}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      className={classNames(
        className,
        {
          fullWidth,
        },
        anchor && `anchor${capitalize(anchor)}`,
        align && !fullWidth && `align${capitalize(align)}`
      )}
      componentWidth={ref?.offsetWidth || 0}
      componentHeight={ref?.offsetHeight || 0}
    >
      {children}
    </StyledDiv>
  );
};
