import { TypedDocumentNode, gql } from '@apollo/client';

import { Rarity, Sport } from '__generated__/globalTypes';
import { withFragments } from 'gql';

import { useOwnBlockchainCards_CurrentUser } from './__generated__/useOwnBlockchainCards.graphql';

export const useOwnBlockchainCards = withFragments(
  (user?: useOwnBlockchainCards_CurrentUser | null, sport?: Sport) => {
    if (!user) return 0;

    let cardsCount = user.footballUserProfile?.cardsCount;
    if (sport === Sport.NBA) {
      cardsCount = user.nbaUserProfile?.cardsCount;
    }
    if (sport === Sport.BASEBALL) {
      cardsCount = user.baseballUserProfile?.cardsCount;
    }

    return Object.entries(cardsCount ?? {}).reduce(
      (acc: number, [key, count]) => {
        if (key !== Rarity.common && typeof count === 'number') {
          return acc + count;
        }
        return acc;
      },
      0
    );
  },
  {
    CurrentUser: gql`
      fragment useOwnBlockchainCards_CurrentUser on CurrentUser {
        slug
        nbaUserProfile {
          id
          cardsCount {
            limited
            rare
            superRare
            unique
          }
        }
        baseballUserProfile {
          id
          cardsCount {
            limited
            rare
            superRare
            unique
          }
        }
        footballUserProfile {
          id
          cardsCount {
            limited
            rare
            superRare
            unique
          }
        }
      }
    ` as TypedDocumentNode<useOwnBlockchainCards_CurrentUser>,
  }
);
